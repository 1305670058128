import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { Loadable } from 'utils/ComponentLoader';

const AdminDashboardComponent = Loadable(
  () => import('pages/admin/AdminDashboardComponent'),
  'admin-dashboard',
);

const PrivateAdminDashboard = () => {
  const accessToken = useAppSelector((state) => state.user.currentUser?.accessToken);
  const userType = useAppSelector((state) => state.user.currentUser?.userType);
  const isAdmin = userType === 'ADMIN';

  const authenticated = !!(accessToken && isAdmin);

  return authenticated ? <AdminDashboardComponent /> : <Navigate to="/" />;
};

export default PrivateAdminDashboard;
